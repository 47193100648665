.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mycontainer-p-y {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.changemdp {
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 17rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.section-form {
  background: rgba(189, 189, 189, .2);
  padding: 10px 20px 10px 20px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.form-control,
.input-group-append,
.input-group {
  height: 30px;
  margin-bottom: 0px;
}

.custom-select {
  height: 35px;
  margin-bottom: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}